.AmentiseOne {
  background-color: transparent;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  height: 100px;
  width: 100px;
  position: relative;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.AmentiseOneHover {
  position: absolute;
  transition: all 0.7s ease;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  overflow: hidden;
  width: 100%;
  display: grid;
  place-items: center;
}

.AmentiseOne:hover .am-img {
  opacity: 0;
  scale: 0%;
}
.AmentiseOne:hover p {
  opacity: 1;
  scale: 100%;
}

.AmentiseOne p {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  opacity: 0;
  scale: 0%;
}
.elementToFadeInAndOut {
  opacity: 1;
  animation: fade 2s linear;
}

.bgImageWithUrl {
  background-image: url('../../assets/HomePageImages/backUpCoin.svg');
  background-size: cover;
  background-position: center;
  width: 400px;
  height: 300px;
}
.Bounce_img {
  width: auto;
  right: 120px;
  height: 150px;
  position: absolute;
  animation-name: imagebounce;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
}

#removeControls::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

@keyframes imagebounce {
  from {
    margin-top: -80px;
  }
  to {
    margin-top: 0px;
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
